<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div
									class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.servisTalebiNumarasi }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0"
										v-if="$router.options.history.state.back != null" icon="pi pi-times"
										@click="$router.go(-1)" />
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<Message severity="success" icon="pi-lock-open" v-if="mainData.durumAciklamasi == 1">Aktivite durumu: <b>{{ mainData.durumAciklamasiName }}</b></Message>
		<Message severity="warn" icon="pi-exclamation-triangle" v-if="mainData.durumAciklamasi == 2 || mainData.durumAciklamasi == 3 || mainData.durumAciklamasi == 4 || mainData.durumAciklamasi == 930710000 || mainData.durumAciklamasi == 930710001 || mainData.durumAciklamasi == 930710002">Aktivite durumu: <b>{{ mainData.durumAciklamasiName }}</b></Message>
		<Message severity="error" icon="pi-lock" v-if="mainData.durumAciklamasi == 5">Aktivite durumu: <b>{{ mainData.durumAciklamasiName }}</b></Message>
		<Message severity="error" icon="pi-lock" v-if="mainData.durumAciklamasi == 6">Aktivite durumu: <b>{{ mainData.durumAciklamasiName }}</b></Message>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="SERVİS TALEBİ" entityName="incident">
					</EntityHeader>
					<div class="grid">
						<div class="col-5">
							<div class="field p-fluid">
								<EntityLookup id="firma" v-model="mainData.firmaName" ref="entity_firma" label="Firma"
									entityName="account" nameField="name" :state="true" :required="true" :disabled="isDisabled"
									@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.firmaName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.firmaName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="ilgiliKisiName" v-model="mainData.ilgiliKisiName" ref="entity_ilgiliKisi"
									label="İlgili Kişi" entityName="contact" nameField="fullname" :state="true" :disabled="isDisabled"
									parentFieldName="parentcustomerid" :parentId="firmaSelected"
									@itemSelected="ilgiliKisiSelected = $event" @itemCleared="ilgiliKisiSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<EntityLookup id="konum" v-model="mainData.konumName" ref="entity_konum"
									label="Konum" entityName="site" nameField="name" :state="true" :required="true" :disabled="isDisabled"
									@itemSelected="konumSelected = $event"
									@itemCleared="konumSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.konumName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.konumName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="durumAciklamasi">Durum Açıklaması</label>
								<Dropdown id="durumAciklamasi" v-model="mainData.durumAciklamasi" :options="SM_durumAciklamasi" optionLabel="Value" optionValue="AttributeValue" placeholder="Durum Açıklaması" :showClear="true" :disabled="isDisabled"/>
								<span v-if="v$.mainData.durumAciklamasi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.durumAciklamasi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>TEZGAH </h5>
					<div class="grid">
						<div class="col-4">
							<div class="field p-fluid">
								<EntityLookup id="tezgah" v-model="mainData.tezgahName" ref="entity_tezgah"
									label="Tezgah" entityName="bm_unite" nameField="bm_serino" :state="true" :required="true" :disabled="isDisabled"
									secondNameField="bm_urunid" secondNameFieldType="lookup" parentFieldName="bm_kurulummusteriid" :parentId="firmaSelected" @itemSelected="tezgahSelected = $event"
									@itemCleared="tezgahSelected = null"></EntityLookup>
								<span v-if="v$.mainData.tezgahName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.tezgahName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<EntityLookup id="urun" v-model="mainData.tezgahModelName" ref="entity_tezgahModel"
									label="Tezgah Modeli" entityName="product" nameField="name" :state="true" 
									@itemSelected="tezgahModelSelected = $event"
									@itemCleared="tezgahModelSelected = null" disabled>
								</EntityLookup>
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="garantiDahilmi">Garanti Dahil mi?</label>
								<Dropdown id="garantiDahilmi" v-model="mainData.garantiDahilmi" :options="SM_garantiDahilmi" optionLabel="Value" optionValue="AttributeValue" placeholder="Garanti Dahil mi?" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>SERVİS VE MALİYET BİLGİSİ</h5>
					<div class="grid">
						<div class="col-6">
							<div class="field p-fluid">
								<label for="baslik">Başlık</label>
								<InputText id="baslik" type="text" v-model="mainData.baslik" class="uppercase" :disabled="isDisabled"/>
								<span v-if="v$.mainData.baslik.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.baslik.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="servisTalepTarihi">Servis Talep Tarihi</label>
								<Calendar showButtonBar id="servisTalepTarihi" v-model="mainData.servisTalepTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
								<span v-if="v$.mainData.servisTalepTarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.servisTalepTarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="urun" v-model="mainData.talebiAcanName" ref="entity_talebiAcan"
									label="Talebi Açan" entityName="bm_servispersonel" nameField="bm_name" :state="true" :disabled="isDisabled"
									@itemSelected="talebiAcanSelected = $event" @itemCleared="talebiAcanSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.talebiAcanName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.talebiAcanName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="aciklama">Açıklama</label>
								<Textarea id="aciklama" rows="4" :autoResize="false" v-model="mainData.aciklama" :disabled="isDisabled" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>FATURALANDIRMA</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="ucretliUcretsiz">Ücretli / Ücretsiz</label>
								<Dropdown id="ucretliUcretsiz" v-model="mainData.ucretliUcretsiz" :options="SM_ucretliUcretsiz" optionLabel="Value" optionValue="AttributeValue" placeholder="Ücretli / Ücretsiz" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="servisCalismaTutari">Servis Çalışma Tutarı</label>
								<InputNumber id="servisCalismaTutari" v-model="mainData.servisCalismaTutari" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="paraBirimi" v-model="mainData.paraBirimiName" ref="entity_paraBirimi"
									label="Para Birimi" entityName="transactioncurrency" nameField="currencyname" :state="true" :disabled="isDisabled"
									@itemSelected="paraBirimiSelected = $event" @itemCleared="paraBirimiSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="muhasebeyeTeslimTarihi">Muhasebeye Teslim Tarihi</label>
								<Calendar showButtonBar id="muhasebeyeTeslimTarihi" v-model="mainData.muhasebeyeTeslimTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
							</div>
						</div>

						<div class="col-3">
							<div class="field p-fluid">
								<label for="siparisnumarasi">Sipariş Numarası</label>
								<InputText id="siparisnumarasi" type="text" v-model="mainData.siparisNumarasi" :disabled="isDisabled" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>GÖREVLENDİRME MAİLİ</h5>
					<div class="grid">
						<div class="col-12">
							<div class="field p-fluid">
								<label for="garantiDahilmi">Görevlendirme Maili</label>
								<MultiSelect v-model="selectedMobilKullanicilar" :options="mobilKullanicilarData" optionLabel="name" placeholder="Kaynak seçiniz" :filter="true" class="multiselect-custom" :disabled="isDisabled">
									<template #value="slotProps">
										<div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.code">
											<img src="layout/images/entityImages/user.gif" class="mr-2" width="18" />
											<div>{{option.name}}</div>
										</div>
										<template v-if="!slotProps.value || slotProps.value.length === 0">
											Kaynak seçiniz
										</template>
									</template>
									<template #option="slotProps">
										<div class="country-item">
											<img src="layout/images/entityImages/user.gif" class="mr-2" width="18" />
											<div>{{slotProps.option.name}}</div>
										</div>
									</template>
								</MultiSelect>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<div class="flex align-items-center">
						<div class="mr-3" style="height:39px">
							<h5 style="line-height: 39px;">SERVİS RAPORLARI</h5>
						</div>
						<div style="height:39px">
							<Button v-if="mainData.sonuc != 930710000" icon="pi pi-plus" class="p-button-rounded" @click="ServisRaporuEkle()" :disabled="isDisabled" />
						</div>
					</div>

					<DataTable
						:value="servisRaporlariData"
						selectionMode="single"
						:rowHover="true"
						@rowSelect="onRowSelectServisRaporu($event)"
						responsiveLayout="scroll">
						<template #empty>
							Kayıt Bulunamadı.
						</template>
						<template #loading>
							Veriler yükleniyor, lütfen bekleyiniz...
						</template>
						<Column field="baslangic" header="Başlangıç" headerStyle="width:150px"></Column>
						<Column field="bitis" header="Bitiş" headerStyle="width:150px"></Column>
						<Column field="turu" header="Türü" headerStyle="width:150px"></Column>
						<Column field="kaynaklar" header="Kaynaklar"></Column>
						<Column field="servisUcretlimi" header="Servis Ücretlimi" headerStyle="width:150px"></Column>
						<Column field="servisMaliyeti" header="Servis Maliyeti" headerStyle="width:150px" bodyStyle="text-align: right">
							<template #body="slotProps">
								<div style="padding-right:30px">
									<span class="maliyet-badge tutar">{{ formatNumber(slotProps.data.servisMaliyeti) }} €</span>
								</div>
							</template>
						</Column>
						<Column headerStyle="width: 130px; text-align: center" bodyStyle="text-align: center; overflow: visible">
							<template #body="{data}">
								<Button :label="data.durumName" :class="OnRowStyleServisRaporu(data.durumId)" style="width: 100px;" />
							</template>
						</Column>
						<Column headerStyle="width: 0.5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
							<template #body="{data}">
								<Button icon="pi pi-file" class="p-button-rounded p-button-info" @click="DownloadDokumanYonetimi(data.annotationId)" />
							</template>
						</Column>
					</DataTable>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<div class="flex align-items-center">
						<div class="mr-3" style="height:39px">
							<h5 style="line-height: 39px;">YEDEK PARÇALAR</h5>
						</div>
					</div>

					<CrmDataTable :key="CrmDataTable_bm_yedekparcateklifsiparis_key" baseEntityName="bm_yedekparcateklifsiparis" :options="CrmDataTable_bm_yedekparcateklifsiparis_options" :rows="50"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
				</div>
			</div>

			<div class="col-12">
				<div class="field p-fluid">
					<Annotation objectTypeCode="112" logicalName="incident" :objectId="entityId" ></Annotation>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 112,
			mainData: {},
			servisRaporlariData: [],

			mobilKullanicilarData: [],
			selectedMobilKullanicilar: null,
			isDelete: false,

			CrmDataTable_bm_yedekparcateklifsiparis_key: 0,
			CrmDataTable_bm_yedekparcateklifsiparis_options: {
				searchAttributes: ["bm_teklifbasligi", "bm_teklifno", "bm_siparisno", "bm_musterigercekidname", "bm_urunidname", "bm_uniteidname"],
				filterParent: [
					{ 'name': 'bm_servistalebinoid', 'filterOperator': 'eq', 'value': this.entityId }
				],
				forceSavedQueryId: '85EC6CFB-AC75-E211-9AD9-E61F135C9177',
				views: [
					{ 'viewName': '5 - Tümü', 'savedQueryId': '85EC6CFB-AC75-E211-9AD9-E61F135C9177' },
				],
				preventSystemViews: true,
				preventUserViews: true
            },

			nestedMenuitems: [
				{
					label: 'Kaydet',
					icon: 'pi pi-fw pi-save',
					visible: () => this.isDisabledVisible(),
					command: () => { this.OnSave(false); },
                },
				{
					label: 'Kaydet & Kapat',
					icon: 'pi pi-fw pi-save',
					visible: () => this.isDisabledVisible(),
					command: () => { this.OnSave(true); },
                },
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
				{
					label: 'Servis Talebini ÇÖZ',
					icon: 'pi pi-fw pi-check-circle',
					visible: () => this.isDisabledVisible(),
					command: () => { this.ServisTalebiniCoz(); },
                },
				{
					label: 'Servis Talebini İPTAL ET',
					icon: 'pi pi-fw pi-times-circle',
					visible: () => this.isDisabledVisible(),
					command: () => { this.ServisTalebiniIptalEt(); },
                },
				{
					label: 'Maliyet Bilgisi GÖNDER',
					icon: 'pi pi-fw pi-send',
					visible: () => this.isSeviyeServis,
					command: () => { this.MaliyetBilgisiGonder(); },
                },
				{
					label: 'Görev Yazısı GÖNDER',
					icon: 'pi pi-fw pi-send',
					visible: () => this.isDisabledVisible(),
					command: () => { this.GorevYazisiGonder(); },
                },
				{
					label: 'Görev Yazısı GÖSTER',
					icon: 'pi pi-fw pi-print',
					visible: () => this.isDisabledVisible(),
					command: () => { this.GotoReport('ServisGorevlendirme','guid'); },
                },
				{
					label: 'Yeniden ETKİNLEŞTİR',
					icon: 'pi pi-fw pi-history',
					visible: () => this.isDurumKapalimi,
					command: () => { this.ServisTalebiniYenidenEtkinlestir(); },
                },
			]
		}
	},
	async created() {
		this.crmService = new CrmService();
		window.addEventListener('scroll', this.handleScroll);

		this.OnLoad();
	},
	computed: {
		entityId() {
			return this.$route.params.id;
		},
		isDisabled() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Servis Talepleri');
					if (filtered.length > 0) {
						return filtered[0].isWrite == false;
					}
				}
			}

			if(this.mainData.durumAciklamasi == 5 || this.mainData.durumAciklamasi == 6) {
				return true;
			}

			return false;
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Servis Talepleri');
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		isDurumKapalimi() {
			if(this.mainData.durumAciklamasi == 5 || this.mainData.durumAciklamasi == 6) {
				return true;
			}

			return false;
		},
		profileData() {
			return this.$store.getters.getProfile;
		},
		isSeviyeServis(){
			let isSeviye = (this.profileData.guvenlikSeviyeleri.indexOf("Servis Yönetici") > -1);
			return isSeviye;
		},
		SM_durumAciklamasi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'statuscode');
		},
		SM_garantiDahilmi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_garantidahil', 'bool');
		},
		SM_ucretliUcretsiz: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_ucretliucretsiz', 'bool');
		},
		firmaSelected: {
			get: function () {
				if (this.mainData["firmaId"]) {
					return { "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["firmaId"] = null;
					this.mainData["firmaName"] = null;
				}
				else {
					this.mainData["firmaId"] = newValue.Value;
					this.mainData["firmaName"] = newValue.Name;
				}
			}
		},
		ilgiliKisiSelected: {
			get: function () {
				if (this.mainData["ilgiliKisiId"]) {
					return { "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["ilgiliKisiId"] = null;
					this.mainData["ilgiliKisiName"] = null;
				}
				else {
					this.mainData["ilgiliKisiId"] = newValue.Value;
					this.mainData["ilgiliKisiName"] = newValue.Name;
				}
			}
		},
		konumSelected: {
			get: function () {
				if (this.mainData["konumId"]) {
					return { "Value": this.mainData["konumId"], "Name": this.mainData["konumName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["konumId"] = null;
					this.mainData["konumName"] = null;
				}
				else {
					this.mainData["konumId"] = newValue.Value;
					this.mainData["konumName"] = newValue.Name;
				}
			}
		},
		tezgahSelected: {
			get: function () {
				if (this.mainData["tezgahId"]) {
					return { "Value": this.mainData["tezgahId"], "Name": this.mainData["tezgahName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["tezgahId"] = null;
					this.mainData["tezgahName"] = null;
				}
				else {
					this.mainData["tezgahId"] = newValue.Value;
					this.mainData["tezgahName"] = newValue.Name;
				}
			}
		},
		tezgahModelSelected: {
			get: function () {
				if (this.mainData["tezgahModelId"]) {
					return { "Value": this.mainData["tezgahModelId"], "Name": this.mainData["tezgahModelName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["tezgahModelId"] = null;
					this.mainData["tezgahModelName"] = null;
				}
				else {
					this.mainData["tezgahModelId"] = newValue.Value;
					this.mainData["tezgahModelName"] = newValue.Name;
				}
			}
		},
		talebiAcanSelected: {
			get: function () {
				if (this.mainData["talebiAcanId"]) {
					return { "Value": this.mainData["talebiAcanId"], "Name": this.mainData["talebiAcanName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["talebiAcanId"] = null;
					this.mainData["talebiAcanName"] = null;
				}
				else {
					this.mainData["talebiAcanId"] = newValue.Value;
					this.mainData["talebiAcanName"] = newValue.Name;
				}
			}
		},
		paraBirimiSelected: {
			get: function () {
				if (this.mainData["paraBirimiId"]) {
					return { "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["paraBirimiId"] = null;
					this.mainData["paraBirimiName"] = null;
				}
				else {
					this.mainData["paraBirimiId"] = newValue.Value;
					this.mainData["paraBirimiName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnLoad(){
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			debugger;
			if (this.entityId != null) {
				try {
					debugger;

					let data = await this.crmService.getServisTalebiById(this.entityId);
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}
					
					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];
						this.servisRaporlariData = data.servisRaporlariData;
						this.selectedMobilKullanicilar = data.gorevlendirmeData;

						const pageHistoryItem = { entityLetter: 'STa', entityName: 'incident', ID: this.mainData.entityId, title: this.mainData.servisTalebiNumarasi + '\r\n\r\n' + this.mainData.firmaName };
						this.$store.commit('SET_PAGEHISTORY', pageHistoryItem);

						if (this.mainData["firmaId"]) {
							this.$refs.entity_firma.setDefaultData({ "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] });
						}

						if (this.mainData["ilgiliKisiId"]) {
							this.$refs.entity_ilgiliKisi.setDefaultData({ "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] });
						}

						if (this.mainData["konumId"]) {
							this.$refs.entity_konum.setDefaultData({ "Value": this.mainData["konumId"], "Name": this.mainData["konumName"] });
						}

						if (this.mainData["tezgahId"]) {
							this.$refs.entity_tezgah.setDefaultData({ "Value": this.mainData["tezgahId"], "Name": this.mainData["tezgahName"] });
						}

						if (this.mainData["tezgahModelId"]) {
							this.$refs.entity_tezgahModel.setDefaultData({ "Value": this.mainData["tezgahModelId"], "Name": this.mainData["tezgahModelName"] });
						}

						if (this.mainData["talebiAcanId"]) {
							this.$refs.entity_talebiAcan.setDefaultData({ "Value": this.mainData["talebiAcanId"], "Name": this.mainData["talebiAcanName"] });
						}

						if (this.mainData["paraBirimiId"]) {
							this.$refs.entity_paraBirimi.setDefaultData({ "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] });
						}

						if (this.mainData["servisTalepTarihi"]) {
							this.mainData["servisTalepTarihi"] = new Date(this.mainData.servisTalepTarihi);
						}

						if (this.mainData["muhasebeyeTeslimTarihi"]) {
							this.mainData["muhasebeyeTeslimTarihi"] = new Date(this.mainData.muhasebeyeTeslimTarihi);
						}

						this.CrmDataTable_bm_yedekparcateklifsiparis_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_yedekparcateklifsiparis_key++;
					}
				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				//this.$router.push('/');
				loader.hide();
			}

			let mobilKullaniciDataTemp = await this.crmService.GetMobilKullanicilar();
			debugger;
			if (mobilKullaniciDataTemp != null) {
				this.mobilKullanicilarData = mobilKullaniciDataTemp.jsonData;
			}
		},
		async OnSave(kapat) {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					if (this.selectedMobilKullanicilar.length > 0) {
						this.mainData["gorevlendirme"] = this.selectedMobilKullanicilar;
					}
					
					const response = await this.crmService.getServisTalebiUpdate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});

							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		async OnDelete(){
            this.deleteItem('incident', this.entityId);
        },
		CheckAccessPrivilege() {
			return this.isDelete;
		},
		ServisTalebiniCoz() {
			debugger;
			this.$confirm.require({
                message: 'Talebi ÇÖZMEK istediğinize emin misiniz?',
                header: 'ONAY',
                icon: 'pi pi-check-circle',
                accept: () => {
                    try {
						this.crmService.getServisTalebiniCoz(this.entityId)
						.then(response => {
							if (response.hata == true) {
								this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
							}else {
								if (window.opener) {
									window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
								}

								this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Servis Talebi Başarıyla Tamamlandı', life: 3000});
								this.OnLoad();
							}
						})
						.catch(error => {
							debugger;
							console.log(error);
						});
					} catch (error) {
						this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
					}
                },
            });
		},
		ServisTalebiniIptalEt() {
			debugger;
			this.$confirm.require({
                message: 'Talebi İPTAL istediğinize emin misiniz?',
                header: 'ONAY',
                icon: 'pi pi-times-circle',
                accept: () => {
                    try {
						this.crmService.getServisTalebiniIptalEt(this.entityId)
						.then(response => {
							if (response.hata == true) {
								this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
							}else {
								if (window.opener) {
									window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
								}

								this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Servis Talebi İptal Edildi', life: 3000});
								this.OnLoad();
							}
						})
						.catch(error => {
							debugger;
							console.log(error);
						});
					} catch (error) {
						this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
					}
                },
            });
		},
		ServisTalebiniYenidenEtkinlestir() {
			debugger;
			this.$confirm.require({
                message: 'Talebi ETKİNLEŞTİRMEK istediğinize emin misiniz?',
                header: 'ONAY',
                icon: 'pi pi-times-circle',
                accept: () => {
                    try {
						this.crmService.getServisTalebiniYenidenEtkinlestir(this.entityId)
						.then(response => {
							if (response.hata == true) {
								this.$toast.add({severity:'error', summary: 'HATA', detail: response.hataAciklamasi, life: 5000});
							}else {
								if (window.opener) {
									window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
								}

								this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Servis Talebi Yeniden Aktifleştirildi', life: 3000});
								this.OnLoad();
							}
						})
						.catch(error => {
							debugger;
							console.log(error);
							this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
						});
					} catch (error) {
						debugger;
						console.log(error);
						this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
					}
                },
            });
		},
		GotoReport(Name, ParamName){
			const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=" + Name + "&" + ParamName + "=" + this.entityId;
			window.open(reportUrl, 'qwe', '_blank');
		},
		async GorevYazisiGonder(){
			let loader = this.$loading.show({
				container: this.$refs.mainContainer
			});

			try {
				let data = await this.crmService.sendGorevlendirmeYazisi(this.entityId);
				loader.hide();
				if (data.hata == true) {
					this.$toast.add({severity: 'error', summary: 'HATA', detail: data.hataAciklamasi, life: 3000});	
				}
				else {
					this.$toast.add({severity: 'success', summary: 'Bilgi', detail: 'Görevlendirme yazısı gönderildi.', life: 3000});	
				}
			} catch (error) {
				debugger;
				console.log(error);
			} finally {
				loader.hide();
			}
		},
		async MaliyetBilgisiGonder(){
			let loader = this.$loading.show({
				container: this.$refs.mainContainer
			});

			try {
				let data = await this.crmService.sendMaliyetBilgisi(this.entityId);
				loader.hide();
				if (data.hata == true) {
					this.$toast.add({severity: 'error', summary: 'HATA', detail: data.hataAciklamasi, life: 3000});	
				}
				else {
					this.$toast.add({severity: 'success', summary: 'Bilgi', detail: 'Maliyet bilgisi gönderildi.', life: 3000});	
				}
			} catch (error) {
				debugger;
				console.log(error);
			} finally {
				loader.hide();
			}
		},
		
		ServisRaporuEkle() {
			debugger;
			this.$router.push({ name: 'serviceappointment_withIncident', params: { incidentid: this.entityId } });
        },
		async DownloadDokumanYonetimi(AnnotationId){
			try {
				window.open(process.env.VUE_APP_SERVICEURL + '/global/GetAnnotationFile/' + AnnotationId, '_blank');
			} catch (error) {
				console.log(error);
			}
		},
		onRowSelectServisRaporu(event) {
			debugger;
			this.$router.push({ name: 'serviceappointment', params: { id: event.data.ID } });
        },
		OnRowStyleServisRaporu(durumId){
			if (durumId == 930710008) { //Devam Ediyor
				return "p-button-success";
			}
			else if(durumId == 930710000){ //Tamamlandı
				return "p-button-danger";
			}
			else {
				return "p-button-warning";
			}
		},
		isDisabledVisible(){
			return !this.isDisabled;
		},
		formatNumber(value) {
			debugger;
			if (value != undefined) {
				return value.toLocaleString('tr-TR');
			}
		},
		getCurrency() {
			let paraBirimi = '';
			switch (this.mainData.paraBirimiName) {
				case "Dolar":
					paraBirimi = " $"
					break;
				case "Euro":
					paraBirimi = " €"
					break;
				case "İsviçre Frankı":
					paraBirimi = " SFr."
					break;
				case "Türk Lirası":
					paraBirimi = " ₺"
					break;
				case "Yen":
					paraBirimi = " ¥"
					break;

				default:
					break;
			}
			return paraBirimi;
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Servis Talepleri');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				firmaName: {
					required: helpers.withMessage('Firma adı alanı dolu olmalıdır!', required),
				},
				konumName: {
					required: helpers.withMessage('Konum alanı dolu olmalıdır!', required),
				},
				durumAciklamasi: {
					required: helpers.withMessage('Durum Açıklamasi alanı dolu olmalıdır!', required),
				},
				tezgahName: {
					required: helpers.withMessage('Tezgah alanı dolu olmalıdır!', required),
				},
				baslik: {
					required: helpers.withMessage('Başlık alanı dolu olmalıdır!', required),
				},
				servisTalepTarihi: {
					required: helpers.withMessage('Servis Talep Tarihi alanı dolu olmalıdır!', required),
				},
				talebiAcanName: {
					required: helpers.withMessage('Talebi Açan alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
	.country-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	::v-deep(.multiselect-custom) {
		.p-multiselect-label:not(.p-placeholder) {
			padding-top: .50rem;
			padding-bottom: .50rem;
		}

		.country-item-value {
			padding: .25rem .5rem;
			border-radius: 3px;
			display: inline-flex;
			margin-right: .5rem;
			background-color: var(--primary-color);
			color: var(--primary-color-text);

			img.flag {
				width: 17px;
			}
		}
	}

	@media screen and (max-width: 640px) {
		.p-multiselect {
			width: 100%;
		}
	}

	.maliyet-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		font-weight: bold;
		font-size: 13px;
		letter-spacing: .3px;

		&.kapali {
			background: #FFCDD2;
			color: #C63737;
		}

		&.tutar {
			background: #C8E6C9;
			color: #256029;
		}

		&.kazanildi {
			background: #B3E5FC;
			color: #23547B;
		}

		&.revize {
			background: #B3E5FC;
			color: #23547B;
		}

		
	}
</style>
